// Our variables
$primary-font: 'Kreon';
$secondary-font: 'Poppins';

$h1-font-size: 4.0em;
$h2-font-size: 2.0em;

// Navigation bar
$navbar-height: 30px;
$nav-color: white;

$header-padding: 0.5em;
$section-padding: 2.0em;
$content-padding: 2.0em;
$blog-item-margin: 1.5em;

$primary-color: #3180ae;
$secondary-color: #e06e54;
