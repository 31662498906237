@font-face {
  font-family: Kreon;
  src: url(../fonts/Kreon-Regular.ttf);
}

@font-face {
  font-family: Poppins;
  src: url(../fonts/Poppins-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Poppins;
  src: url(../fonts/Poppins-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url(../fonts/Poppins-Bold.ttf);
  font-weight: 700;
}
