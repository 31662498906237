@use 'sass:color';
@use 'constants';

html, body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

.font-primary {
  font-family: constants.$primary-font, 'serif';
}

.font-secondary {
  font-family: constants.$secondary-font, 'sans-serif';
}

.text-primary {
  color: constants.$primary-color;
}

.bg-primary {
  background-color: constants.$primary-color !important;
}

.border-primary {
  border-color: constants.$primary-color !important;
}

.text-secondary {
  color: constants.$secondary-color;
}

.w-550 {
  max-width: 550px;
}

.max-w-970 {
  max-width: 970px;
}

.button {
  cursor: pointer;
  color: white;
  background-color: constants.$primary-color !important;

  &:hover, &:focus, &:active {
    background-color: color.adjust(constants.$primary-color, $lightness: 10%) !important;
  }
}

q {
  quotes: "“" "”" "’" "‘";
}

.social-icon {
  padding: 0 5px;

  &.social-icon--github {
    right: 20px;
    top: -100px;
  }

  &.social-icon--mastodon {
    left: 20px;
    top: -180px;
  }
}

#eyes {
  @keyframes rightEyeMovement {
    0%  {transform: translate(0px);}

    /* neutral */
    1%  {transform: translateX(-2px) translateY(1px);}

    /* to the right and stay */
    3%  {transform: translateX(-5px) translateY(2px);}
    7%  {transform: translateX(-5px) translateY(2px);}

    /* to the left and stay */
    8%  {transform: translateX(3px) translateY(1px);}
    14%  {transform: translateX(3px) translateY(1px);}

    /* to the right and stay */
    15%  {transform: translateX(-5px) translateY(2px);}
    20%  {transform: translateX(-5px) translateY(2px);}

    /* eye the visitor */
    21%  {transform: translateX(-2px) translateY(1px);}
    29%  {transform: translateX(-2px) translateY(1px);}

    /* squint */
    35%  {transform: translateX(5px) translateY(1px);}
    40%  {transform: translateX(4px) translateY(0px);}
    50%  {transform: translateX(5px) translateY(1px);}

    /* return back */
    52%  {transform: translateX(-2px) translateY(1px);}
    56%  {transform: translateX(-2px) translateY(1px);}
    58%  {transform: translate(0px);}
  }

  @keyframes leftEyeMovement {
    0%  {transform: translate(0px);}

    /* neutral */
    1%  {transform: translateX(-2px) translateY(1px);}

    /* to the right and stay */
    3%  {transform: translateX(-4px) translateY(2px);}
    7%  {transform: translateX(-4px) translateY(2px);}

    /* to the left and stay */
    8%  {transform: translateX(3px) translateY(1px);}
    14%  {transform: translateX(3px) translateY(1px);}

    /* to the right and stay */
    15%  {transform: translateX(-4px) translateY(2px);}
    20%  {transform: translateX(-4px) translateY(2px);}

    /* eye the visitor */
    21%  {transform: translateX(-2px) translateY(1px);}
    29%  {transform: translateX(-2px) translateY(1px);}

    /* squint */
    35%  {transform: translateX(-5px) translateY(0px);}
    40%  {transform: translateX(-4px) translateY(0px);}
    50%  {transform: translateX(-5px) translateY(0px);}

    /* return back */
    52%  {transform: translateX(-2px) translateY(1px);}
    56%  {transform: translateX(-2px) translateY(1px);}
    58%  {transform: translate(0px);}
  }

  #eye-right {
    height: 9px;
    width: 9px;
    top: -114px;
    left: 126px;

    animation-name: rightEyeMovement;
    animation-delay: 6s;
    animation-duration: 10s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  #eye-left {
    height: 8px;
    width: 8px;
    top: -114px;
    left: 163px;

    animation-name: leftEyeMovement;
    animation-delay: 6s;
    animation-duration: 10s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
}

small {
  color: #999;
}

.h-space {
  padding: 0 0.2em;
}

.dropdown {
  position: relative;

  /* Hide The disclosure widget: */
  summary {
    list-style: none;
    cursor: pointer;
  }

  summary::-webkit-details-marker {
    display: none;
  }

  /* Detache details content */
  .dropdown-content {
    position: absolute;
    min-inline-size: max-content;
  }

  /* Closing the dropdown on clicking anywhere else */
  details[open] summary::before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    cursor: default;
  }
}
